@import url('https://fonts.googleapis.com/css2?family=Salsa&display=swap');


:root {
  --tw-bg-opacity: 0.5; /* Adjust the opacity as needed */
}



*{
  font-family: 'YourCustomFont' !important;
  font-size: 20px !important;

}

body {
  font-family: 'YourCustomFont' !important;
 background-color: #000000 !important;
 overflow-x: hidden !important;
}
@font-face {
  font-family: 'YourCustomFont';
  src: url('./Fonts/Time.otf') format('opentype');
  /* You can also specify font-weight, font-style, etc. */
}
.desk{
  cursor: url(chand.png) !important;
  background-attachment: fixed;
  background-image: url('./200w.gif')!important;
  background-repeat: no-repeat!important;
  background-size: cover !important;

  /* Set the background color with opacity and color using a CSS variable */
  background-color: rgba(255, 0, 0, var(--tw-bg-opacity)); /* Red color with variable opacity */
}
.desks{
background-color: #000000a5;
}
.mob{
  cursor: url(chand.png) !important;
  background-attachment: fixed;
  background-image: url('./200w.gif')!important;
  background-repeat: no-repeat!important;
  background-size: cover !important;
  background-position: center;

  /* Set the background color with opacity and color using a CSS variable */
  background-color: rgba(255, 0, 0, var(--tw-bg-opacity)); /* Red color with variable opacity */
}

.centerall{
  text-align: center;
  justify-content: center;
  align-items: center;

}

/* Additional font-face rules if you have more fonts */

/* Define global font styles */
.matrix-text {
  color: rgb(255, 255, 255);
  font-family: Courier New,Courier,monospace;
  
}


.image_anos{
  width: 100%;
}
@media screen and (min-width: 1000px) {
                        
                                
  .image_anos{
    width: 80%;
  }

      }



.anos_text{
  font-size: 1rem !important;
  color: rgb(251, 251, 251);
  letter-spacing: 5px;
  font-family: Courier New,Courier,monospace;
 
}


@media screen and (min-width: 1000px){
  .anos_text{
    letter-spacing: 5px;
    font-size: 3rem !important;
    color: rgb(255, 255, 255);
    font-family: Courier New,Courier,monospace;
  
  }
  
}

.fontSize_span_anos_text{
  font-size: 10px !important;
  
}
@media screen and (min-width: 1000px){
  .fontSize_span_anos_text{
    font-size: 26px !important;
  }
  
}


.anon_p{

  color: rgb(251, 251, 251);
  font-family: Courier New,Courier,monospace;
 
}


@media screen and (min-width: 1000px){
  .anon_p{
 
    color: rgb(255, 255, 255);
    font-family: Courier New,Courier,monospace;
  
  }
  
}

.anon_pp{
 font-size: 30px !important;
  color: rgb(255, 255, 255);

}

.image_anos_uni{
  width: 60%;
}

@media screen and (min-width: 1000px){
  .image_anos_uni{
    width: 50%;
  }
}

.image_anos_dex{
  width: 65%;
}

@media screen and (min-width: 1000px){
  .image_anos_dex{
    width: 55%;
  }
}

.blue-text {
  font-size: 1rem !important;
  color: #ffffff;
  font-family: Courier New,Courier,monospace;
 
}


@media screen and (min-width: 1000px){
  .blue-text {
    color: #ffffff;
    font-size: 2.5rem !important;
    font-family: Courier New,Courier,monospace;
   
  }
  
}



.red-text {
  font-size:1rem !important;
  color: #ffffff;
  font-family: Courier New,Courier,monospace;
 
}


@media screen and (min-width: 1000px){
  .red-text {

    font-size: 2.5rem !important;
    color: #ffffff;
  font-family: Courier New,Courier,monospace;
 
  }
  
}



.token_text{
  font-size: 1.4rem !important;
  color: rgb(255, 255, 255);

  
}


@media screen and (min-width: 1000px){
  .token_text{
    font-size: 1.4rem !important;
    color: rgb(255, 255, 255);

   
  }
  
}




.card {
    position: relative;
    width: auto;
    height: 100%;
    background-color: #000;
    border-radius: 8px;
}






























@import"https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap";.inputCls[type=number] {
  -moz-appearance: textfield
}


.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px
}

.card {
  position: relative;
  width: auto;
  height: 100%;
  background-color: #000000c1 !important;
  border-radius: 8px
}

.card-content {
  padding: 20px
}

.card-title {
  margin: 0;
  font-size: 24px
}

.card-description {
  margin: 10px 0
}

.card-link {
  display: inline-block;
  padding: 8px 12px;
  background-color: #ff0400;
  color: #fff;
  text-decoration: none;
  border-radius: 4px
}

.boarding {
  position: absolute;
  top: -4px;
  left: -4px;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  border: 4px solid #ff000046;
  pointer-events: none;
  border-radius: 12px;
  opacity: 0;
  animation: boardingAnimation 2s linear infinite
}

@keyframes boardingAnimation {
  0% {
      opacity: 0
  }

  50% {
      opacity: 1
  }

  to {
      opacity: 0
  }
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: repeat
}

*,:before,:after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb
}

:before,:after {
  --tw-content: ""
}

html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";
  font-feature-settings: normal
}



.tokcolo{
  color:  #fff !important;
  font-size: 3rem !important;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #c1494900 !important;
}


/* Style the audio container */
audio {
  background-color:transparent !important;
  color: white;
  width: 300px;
}

/* Style the audio controls */
audio::-webkit-media-controls {
  background-color: #a306067b;
  border-radius: 40%;
}

audio::-webkit-media-controls-play-button {
  background-color: #7c040481;
}

audio::-webkit-media-controls-volume-slider {
  background-color: #ff222263;
}

audio::-webkit-media-controls-panel {
  background-color: #860000d8;
  color: white;
}

/* Style the audio controls for Firefox */
audio::-moz-media-controls {
  background-color: #444;
  color: white;
}

/* Style the audio controls for Edge/IE */
audio::-ms-media-controls {
  background-color: #444;
  color: white;
}





.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #5a41fea9 !important;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 0.5rem;
  outline: 0;
}







































































:root {
  --glow-color: hsla(0, 0%, 100%, 0.882);
}




.glowing-btn {
  position: relative;
  color: var(--glow-color);
  cursor: pointer;
  padding: 0.25em 1em;
  border: 0.15em solid var(--glow-color);
  border-radius: 0.45em;
 background-color: #000000;
  perspective: 2em;
  font-family: Courier New,Courier,monospace;
  font-size: 2em;
  font-weight: 700;
  letter-spacing: 1em;

  -webkit-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  -moz-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  animation: border-flicker 2s linear infinite;
}

.glowing-txt {
  float: left;
  margin-right: -0.8em;
  -webkit-text-shadow: 0 0 0.125em hsl(0, 0%, 0%),
    0 0 0.45em var(--glow-color);
  -moz-text-shadow: 0 0 0.125em hsl(0, 0%, 0%),
    0 0 0.45em var(--glow-color);
  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em var(--glow-color);
  animation: text-flicker 3s linear infinite;
}

.faulty-letter {
  opacity: 0.5;
  animation: faulty-flicker 2s linear infinite;
}

.glowing-btn::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.7;
  filter: blur(1em);
  transform: translateY(120%) rotateX(95deg) scale(3, 0.35);
  background: var(--glow-color);
  pointer-events: none;
}

.glowing-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: -1;
  background-color: var(--glow-color);
  box-shadow: 0 0 2em 0.2em var(--glow-color);
  transition: opacity 100ms linear;
}

.glowing-btn:hover {
  color: rgba(0, 0, 0, 0.8);
  text-shadow: none;
  animation: none;
}

.glowing-btn:hover .glowing-txt {
  animation: none;
}

.glowing-btn:hover .faulty-letter {
  animation: none;
  text-shadow: none;
  opacity: 1;
}

.glowing-btn:hover:before {
  filter: blur(1.5em);
  opacity: 1;
}

.glowing-btn:hover:after {
  opacity: 1;
}

@keyframes faulty-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 0.1;
  }
  4% {
    opacity: 0.5;
  }
  19% {
    opacity: 0.5;
  }
  21% {
    opacity: 0.1;
  }
  23% {
    opacity: 1;
  }
  80% {
    opacity: 0.5;
  }
  83% {
    opacity: 0.4;
  }

  87% {
    opacity: 1;
  }
}

@keyframes text-flicker {
  0% {
    opacity: 0.1;
  }

  2% {
    opacity: 1;
  }

  8% {
    opacity: 0.1;
  }

  9% {
    opacity: 1;
  }

  12% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0.3;
  }
  30% {
    opacity: 1;
  }

  70% {
    opacity: 0.7;
  }
  72% {
    opacity: 0.2;
  }

  77% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.9;
  }
}

@keyframes border-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 1;
  }
  4% {
    opacity: 0.1;
  }

  8% {
    opacity: 1;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .glowing-btn{
    font-size: 1em;
  }
}




.dextop{
  position: relative;
  top: 8px;
}






.fontSize_span{
  font-size: 14px !important;
}

.matrix-text_fontSize{
  font-size: 30px !important;
  letter-spacing: 5px;
}















.a {
  --glow-color: rgb(253, 96, 96);
  --glow-spread-color: rgba(255, 123, 123, 0.781);
  --enhanced-glow-color: rgb(105, 0, 0);
  --btn-color: rgb(164, 0, 0);
  border: .25em solid var(--glow-color);
  padding: 0.3em 1em;
  color: var(--glow-color);

  font-weight: bold;
  background-color: var(--btn-color);
  border-radius: 1em;
  outline: none;
  box-shadow: 0 0 1em .25em var(--glow-color),
         0 0 4em 1em var(--glow-spread-color),
         inset 0 0 .75em .25em var(--glow-color);
  text-shadow: 0 0 .5em var(--glow-color);
  position: relative;
  transition: all 0.3s;
  -webkit-animation: glowing 1300ms infinite;
  -moz-animation: glowing 1300ms infinite;
  -o-animation: glowing 1300ms infinite;
  animation: glowing 1300ms infinite;
 }
 
 .a::after {
  pointer-events: none;
  content: "";
  position: absolute;
  top: 120%;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--glow-spread-color);
  filter: blur(2em);
  opacity: .7;
  transform: perspective(1.5em) rotateX(35deg) scale(1, .6);
 }


 .footerfont{
  font-size: 12px !important;
 }



 
.am {
  --glow-color: rgb(253, 96, 96);
  
  --enhanced-glow-color: rgb(105, 0, 0);
  --btn-color: rgb(164, 0, 0);
  border: .25em solid var(--glow-color);
  padding: 0.3em 1em;
  color: var(--glow-color);

  font-weight: bold;
  background-color: var(--btn-color);
  border-radius: 1em;
  outline: none;

  position: relative;
  transition: all 0.3s;
 }
 
 .am::after {
  pointer-events: none;
  content: "";
  position: absolute;
  top: 120%;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--glow-spread-color);
  filter: blur(2em);
  opacity: .7;
  transform: perspective(1.5em) rotateX(35deg) scale(1, .6);
 }



 .anon_pm{}





 #blinking-text {
  animation: blink 1s linear infinite;
}





 @keyframes blink {
  0%, 100% {opacity: 1;}
  50% {opacity: 0;}
}















.ifram{
  width: 100vw;
  height: 130vh;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0 auto;
 
}

@media screen and (min-width: 1000px){
  .ifram{
position: absolute;
left: 0;
top: 0;
width: 100%;
height: 100vh;
  }
  }
  